<template>
  <div class="infoPage">
    <div class="conBoxList">
      <!--      <div class="list">-->
      <div class="item" v-for="(item,index) in datalist" :key="index" @click="go(item.historyId)">
        <div class="img scale">
          <img :src="item.historyCover" alt="">
          <div class="eye el-icon-view">&nbsp;{{ item.totalVisitNum }}</div>
        </div>
        <div class="con">
          <div class="tit">
            {{ item.historyTitle }}
          </div>
          <div class="text2"><span v-for="(items,indexs) in item.tags">{{items}}</span></div>
          <div class="conText">
            {{ item.historyDesc }}
          </div>
        </div>
      </div>
    </div>

    <!--    <div class="paging-device">
          <el-pagination
              layout="prev, pager, next"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="current_page"
              :page-size="per_page"
              :total="totalCount">
          </el-pagination>
        </div>-->
  </div>
</template>

<script>
import {historyList, imgURL} from '@/api'
export default {
  name: "lishi",
  components:{
  },
  data(){
    return {
      imgURL,
      url:'',
      totalCount: 0,
      current_page: 1,
      per_page: 4,
      datalist:[],
    }
  },
  created() {

  },
  mounted() {
    this.createlist()
  },
  methods:{
    createlist(){
      historyList({
        historyType:2
      })
          .then(res=>{
            console.log(res)
            if (res.code==200) {
              this.datalist=res.rows
              // this.datalist.tags = res.datalist.tags.split(',')
              this.datalist.forEach((item,index)=>{
                if(item.tags && item.tags.trim().length>0){item.tags = item.tags.split(',')}

              })
            }
          })
    },
    go(id){
      this.$router.push({path:'/history/nationalityInfo',query:{id}})
    },
    select(){

    },
    changep(){

    }
  }
}
</script>

<style scoped lang="less">
.infoPage{
  width: 100%;
  min-height: 800px;
  //padding: 0 0 0 55px;
  .conBoxList{
    display: flex;
    flex-wrap: wrap;
    width: 1200px;
    margin: auto;
    padding: 60px 0 120px 0;
    .item{
      flex: none;
      width: 380px;
      background: #FFFFFF;
      border-radius: 20px;
      overflow: hidden;
      cursor: pointer;
      margin-left: 30px;
      margin-bottom: 60px;
      &:nth-child(3n+1){
        margin-left: 0;
      }
      &:hover{
        .img{
          transition:all 1000ms ease;
          //transform: scale(1);
          transform: scale(1.1);
        }
        .con>.tit{
          color: #337D8D;
        }
      }
      .img{
        width: 100%;
        height: 300px;
        position: relative;
        transition:all 500ms ease;
        img{
          width: 100%;
          height: 100%;
        }
        .eye{
          position: absolute;
          top: 16px;
          left: 16px;
          padding: 8px 12px;
          background: #337D8D55;
          border-radius: 5px;
          font-size: 14px;
          font-family: STHeitiSC-Medium, STHeitiSC;
          font-weight: 600;
          color: #ffffff;
        }
      }
      .con{
        padding: 24px;
        .tit{
          font-size: 20px;
          font-family: STSongti-SC-Black, STSongti-SC;
          font-weight: 900;
          color: #333333;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1; //行数
          overflow: hidden;
        }
        .text2{
          margin: 15px 0 10px;
          span{
            font-size: 12px;
            font-family: STSongti-SC-Regular, STSongti-SC;
            font-weight: 400;
            color: #333333;
            padding: 3px 13px 4px 12px;
            margin-left: 10px;
            background: rgba(120, 120, 120, 0.1);
            border-radius: 17px;
            &:nth-child(1){
              margin-left: 0;
            }
          }
        }
        .conText{
          margin-top: 10px;
          font-size: 14px;
          font-family: STSongti-SC-Regular, STSongti-SC;
          font-weight: 400;
          color: #999999;
          line-height: 22px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; //行数
          overflow: hidden;
        }
      }

    }
  }


}
</style>

